export default {
    yj: { // 一建
        qh: {
            tit: '2024精讲启航班',
            desc: [
                '项目管理、工程经济、法规、建筑',
                '机电、市政公用、公路、水利水电'
            ],
            jpkc: [
                '超前预习课含：公共课+建筑市政、机电',
                '精讲导学课',
                '教材精讲课',
                '必学专题课（公开课）',
                '共性案例课（公开课）'
            ],
            yzzl: [
                '基础精讲讲义',
                '历年在线真题',
                '习题解析讲义'
            ],
            zsfw: [
                '手机电脑同步听课',
                '视频讲义离线缓存',
                '海量题库刷题提升',
                '制定专属学习计划',
                '24小时在线答疑',
                '班主任1对1服务',
                '报名提醒',
                '学习跟踪'
            ],
            kcbz: [
                {
                    tit: '超前预习课',
                    desc: [
                        '新课程提前预习，专业老师进行提前布局，按照历年考情专项辅导',
                    ]
                },
                {
                    tit: '备考导学课',
                    desc: [
                        '考情分析、考试介绍、考点分布、学习方法及学习计划安排，达到入门、熟悉学习方法的目标',
                    ]
                },
                {
                    tit: '基础精讲课',
                    desc: [
                        '根据近三年考试分值分布情况，梳理考试必学目标内容；',
                        '题点结合强化记忆，高效学习，帮助考生循序渐进掌握知识点及基本考点，全面夯实基础',
                    ]
                }
            ],
            jg: {
                gg: 698,
                sw: 798,
                qk: 1598
            },
            routerid: {
                course_category_id: 1135,
                class_type_id: 98
            },
        },
        cx: {
            tit: '2024畅学保障班',
            desc: [
                '项目管理、工程经济、法规、建筑',
                '机电、市政公用、公路、水利水电'
            ],
            jpkc: [
                '精讲导学课',
                '教材精讲课',
                '冲刺专题课',
                '共性案例课',
                '必学专题课（公开课）',
                '超前预习课（公共课+建筑、市政、机电）',
                '共性案例课（公开课） '
            ],
            yzzl: [
                '基础精讲讲义',
                '思维导图',
                '历年在线真题',
                '习题解析讲义',
                '冲刺专题讲义',
                '考前两套预测卷',
                '考前密训资料',
            ],
            zsfw: [
                '手机电脑同步听课',
                '视频讲义离线缓存',
                '海量题库刷题提升',
                '制定专属学习计划',
                '24小时在线答疑',
                '班主任1对1服务',
                '报名提醒',
                '报考协助',
                '学习跟踪',
                '免费重修一年'
            ],
            kcbz: [
                {
                    tit: '冲刺专题课',
                    desc: [
                        '以考点形式理清科目内各种真题类型，以及解答思路；',
                        '归纳总结记忆技巧，精简核心考试信息；',
                        '设置实用性更强实战专题，攻克考试盲区，针对性学习；',
                        '快速记忆，攻克特殊题型解题思路，有助于增强实战能力。',
                    ]
                },
                {
                    tit: '真题解析课',
                    desc: [
                        '针对近年考试真题情况，具体分析考试题目，考试考点和考察方法；',
                        '带领学员了解具体考试难度，有针对性的复习；',
                        '熟悉考试真题解题思路和方法',
                    ]
                },
            ],
            jg: {
                gg: 1280,
                sw: 1580,
                qk: 3980
            },
            routerid: {
                course_category_id: 1135,
                class_type_id: 99
            },
        },

    },
    ej: { // 二建
        qh: {
            tit: '2025精讲启航班',
            desc: [
                '施工管理、法规、建筑、机电',
                '市政公用、公路、矿业、水利水电'
            ],
            jpkc: [
                '备考预习课',
                '教材精讲课',
                '真题解析课',
            ],
            yzzl: [
                '基础精讲讲义',
                '历年在线真题',
                '习题解析讲义'
            ],
            zsfw: [
                '手机电脑同步听课',
                '视频讲义离线缓存',
                '海量题库刷题提升',
                '制定专属学习计划',
                '24小时在线答疑',
                '班主任1对1服务',
                '报名提醒',
                '报考协助',
                '学习跟踪'
            ],
            kcbz: [
                {
                    tit: '备考预习课',
                    desc: [
                        '考情分析、考试介绍、考点分布、学习方法及学习计划安排，达到入门、熟悉学习方法的目标。',
                    ]
                },
                {
                    tit: '教材精讲课',
                    desc: [
                        '1. 根据近三年考试分之分布情况，梳理考试必学目标内容；',
                        '2. 题点结合强化记忆，高效学习，帮助考生循序渐进掌握知识点及基本考点，全面夯实基础。',
                    ]
                },
                {
                    tit: '真题解析课',
                    desc: [
                        '1. 针对近年考试真题情况，具体分析考试题目，考试考点和考察方法；',
                        '2. 带领学员了解具体考试难度，有针对性的复习；',
                        '3. 熟悉考试真题解题思路和方法。',
                    ]
                }
            ],
            jg: {
                gg: 880,
                sw: 980,
                qk: 1980
            },
            routerid: {
                course_category_id: 1145,
                class_type_id: 94
            },
        },
        cx: {
            tit: '2025畅学保障班',
            desc: [
                '施工管理、法规、建筑、机电',
                '市政公用、公路、矿业、水利水电'
            ],
            jpkc: [
                '备考预习课',
                '教材精讲课',
                '冲刺串讲课',
                '习题精析课',
                '真题解析课',
            ],
            yzzl: [
                '基础精讲讲义',
                '历年在线真题',
                '习题解析讲义',
                '冲刺专题讲义',
                '每科2套考前测试卷',
            ],
            zsfw: [
                '手机电脑同步听课',
                '视频讲义离线缓存',
                '海量题库刷题提升',
                '制定专属学习计划',
                '24小时在线答疑',
                '班主任1对1服务',
                '报名提醒',
                '报考协助',
                '学习跟踪',
                '免费重修一年',
            ],
            kcbz: [
                {
                    tit: '习题解析课',
                    desc: [
                        '1. 熟悉考试题型及得分技巧，掌握命题规律及高分考试范围，资深名师精编密卷金题，带领学习今年预测考点考题；',
                        '2. 深度剖析考题考点出题规律，命中规律；',
                        '3. 让学员了解考试题型，掌握命题规律，针对性学习。',
                    ]
                },
                {
                    tit: '冲刺专题课',
                    desc: [
                        '1. 以考点形式理清科目内各种真题类型，以及解答思路；',
                        '2. 归纳总结记忆技巧，精简核心考试信息；',
                        '3. 设置实用性更强实战专题，攻克考试盲区，针对性学习；',
                        '4. 快速记忆，攻克特殊题型解题思路，有助于增强实战能力。',
                    ]
                },
            ],
            jg: {
                gg: 1280,
                sw: 1580,
                qk: 2980
            },
            routerid: {
                course_category_id: 1135,
                class_type_id: 95
            },
        },

    },
    aq: { // 安全
        qh: {
            tit: '2024精讲启航班',
            desc: [
                '法规、管理、技术安全实务',
                '(道路运输、煤矿、化工建筑施工', '金属冶炼金属非金属矿山、其他)'
            ],
            jpkc: [
                '备考导学课',
                '基础精讲课',
                '习题解析课',
                '必学专题课（公开课）',
            ],
            yzzl: [
                '基础精讲讲义',
                '历年在线真题',
                '习题解析讲义'
            ],
            zsfw: [
                '手机电脑同步听课',
                '视频讲义离线缓存',
                '海量题库刷题提升',
                '制定专属学习计划',
                '24小时在线答疑',
                '班主任1对1服务',
                '报名提醒',
                '学习跟踪'
            ],
            kcbz: [
                {
                    tit: '备考导学课',
                    desc: [
                        '考情分析、考试介绍、考点分布、学习方法及学习计划安排，达到入门、熟悉学习方法的目标',
                    ]
                },
                {
                    tit: '基础精讲课',
                    desc: [
                        '根据近三年考试分值分布情况，梳理考试必学目标内容；',
                        '题点结合强化记忆，高效学习，帮助考生循序渐进掌握知识点及基本考点，全面夯实基础',
                    ]
                },
                {
                    tit: '习题解析课',
                    desc: [
                        '精编习题，深度剖析考题考点出题规律，命中规律',
                        '了解考试题型，掌握命题规律，针对性学习',
                    ]
                }
            ],
            jg: {
                gg: 798,
                sw: 898,
                qk: 1998
            },
            routerid: {
                course_category_id: 1154,
                class_type_id: 102
            },
        },
        cx: {
            tit: '2024畅学保障班',
            desc: [
                '法规、管理、技术安全实务',
                '(道路运输、煤矿、化工建筑施工', '金属冶炼金属非金属矿山、其他)'
            ],
            jpkc: [
                '备考导学课',
                '基础精讲课',
                '习题解析课',
                '冲刺专题课',
                '必学专题课（公开课）',
            ],
            yzzl: [
                '基础精讲讲义',
                '历年真题汇编',
                '思维导图',
                '习题解析讲义',
                '冲刺串讲讲义',
                '每科2套考前预测卷',
                '考点速记手册',
                '考前三页纸',
            ],
            zsfw: [
                '手机电脑同步听课',
                '视频讲义离线缓存',
                '海量题库刷题提升',
                '制定专属学习计划',
                '24小时在线答疑',
                '班主任1对1服务',
                '报名提醒',
                '学习跟踪',
                '免费重修一年'
            ],
            kcbz: [
                {
                    tit: '冲刺专题课',
                    desc: [
                        '以考点形式理清科目内各种真题类型，以及解答思路；',
                        '归纳总结记忆技巧，精简核心考试信息；',
                        '设置实用性更强实战专题，攻克考试盲区，针对性练习；',
                        '快速记忆，攻克特殊提醒阶梯思路，增强实战能力',
                    ]
                },
                {
                    tit: '必学专题课',
                    desc: [
                        '以大数据收集历年学习心得，专业定制授课内容，由百位师资精心把握考试方向，筛选星级考点',
                    ]
                },
            ],
            jg: {
                gg: 1280,
                sw: 1980,
                qk: 3980
            },
            jgtitle: {
                gg: '单科',
                sw: '两科联报',
                qk: '全科'
            },
            routerid: {
                course_category_id: 1154,
                class_type_id: 103
            },
        },

    },
    jl: {
        qh: {
            tit: '2024精讲突击班',
            desc: [
                '概论法规、合同管理',
                '目标控制（土建）', '案例分析（土建）'
            ],
            jpkc: [
                '备考导学课',
                '教材精讲课',
                '共性案例难点课',
            ],
            yzzl: [
                '基础精讲讲义',
                '章节智能题库',
                '历年在线真题'
            ],
            zsfw: [
                '手机电脑同步听课',
                '视频讲义离线缓存',
                '海量题库刷题提升',
                '制定专属学习计划',
                '24小时在线答疑',
                '班主任1对1服务',
                '报名提醒',
                '报考协助',
                '学习跟踪'
            ],
            kcbz: [
                {
                    tit: '备考导学课',
                    desc: [
                        '考情分析、考试介绍、考点分布、学习方法及学习计划安排，达到入门、熟悉学习方法的目标',
                    ]
                },
                {
                    tit: '教材精讲班',
                    desc: [
                        '根据近三年考试分值分布情况，梳理考试必学目标内容；题点结合强化记忆，高效学习，帮助考生循序渐进掌握知识点及基本考点、全面夯实基础。',
                    ]
                },
                {
                    tit: '共性案例难点课',
                    desc: [
                        '根据近3-5年考试规律及评卷指标，精确锁定案例分析题关键得分词句；案例题专项训练，快速提升案例分析题解题、得分能力。案例题的专项训练，快速提升案例分析题的读题、解题、得分能力。',
                    ]
                }
            ],
            jg: {
                gg: 488,
                sw: 588,
                qk: 1598
            },
            routerid: {
                course_category_id: 1291,
                class_type_id: 19
            },
        },
        cx: {
            tit: '2024通关无忧班',
            desc: [
                '概论法规、合同管理',
                '目标控制（土建）', '案例分析（土建）'
            ],
            jpkc: [
                '备考导学课',
                '教材精讲课',
                '共性案例难点课',
                '核心提分课',
                '考点强化课',
            ],
            yzzl: [
                '基础精讲讲义',
                '章节智能题库',
                '历年在线真题',
                '习题解析讲义',
                '冲刺专题讲义',
                '考前预测资料',
            ],
            zsfw: [
                '手机电脑同步听课',
                '视频讲义离线缓存',
                '海量题库刷题提升',
                '制定专属学习计划',
                '24小时在线答疑',
                '班主任1对1服务',
                '报名提醒',
                '报考协助',
                '学习跟踪',
                '免费重修一年',
            ],
            kcbz: [
                {
                    tit: '核心提分/点题课',
                    desc: [
                        '以考点形式理清科目内各种真题类型，以及解答思路；归纳总结记忆技巧，精简核心考试信息；设置实用性更强实战专题，攻克考试盲区，针对性训练。快速记忆，攻克特殊题型解题思路，增强实战能力。',
                    ]
                },
                {
                    tit: '考点强化密训',
                    desc: [
                        '独家考前密训，进一步给考生强化考试中的重中之重。增强考试中的信心。',
                    ]
                },
            ],
            jg: {
                gg: 1598,
                sw: 1998,
                qk: 4998
            },
            routerid: {
                course_category_id: 1291,
                class_type_id: 20
            },
        },

    },
    ys: {
        qh: {
            tit: '2022尊享畅学班',
            desc: [
                '药师管理与法规、', '中药学综合知识与技能',
                '中药学专业知识（二）、', '中药学专业知识（一）',
                '药学专业知识（二）、', '药学专业知识（一）',
                '药学综合知识与技能'
            ],
            jpkc: [
                '备考导学',
                '教材精讲',
            ],
            yzzl: [
                '《精讲讲义》电子版',
                '《章节习题电子题库》',
                '《模拟试卷》1套',
                '《单元测试题库》'
            ],
            zsfw: [
                '在线答疑',
                '移动看课',
                '学习追踪',
                '报名协助',
                '通用学习计划',
                '考试结束后答案解析',
            ],
            kcbz: [
                {
                    tit: '基础精讲课',
                    desc: [
                        '适合零基础或基础较差、时间宽裕的成员，以新大纲、新教材为标杆逐章逐节梳理考试点；',
                        '根据近三年考试分之分布情况，梳理考试必学目标内容；',
                        '题点结合强化记忆，高效学习，帮助考生循序渐进掌握知识点及基本考点，全面夯实基础'
                    ]
                },
            ],
            jg: {
                gg: 588,
                // sw: 588,
                qk: 1598
            },
            routerid: {
                course_category_id: 1165,
                class_type_id: 29
            },
        },
        cx: {
            tit: '2022高效点题班',
            desc: [
                '药师管理与法规、', '中药学综合知识与技能',
                '中药学专业知识（二）、', '中药学专业知识（一）',
                '药学专业知识（二）、', '药学专业知识（一）',
                '药学综合知识与技能'
            ],
            jpkc: [
                '备考导学',
                '教材精讲',
                '冲刺串讲',
            ],
            yzzl: [
                '《精讲讲义》电子版',
                '《真题解析课讲义》电子版',
                '《章节习题电子题库》',
                '《历年考试真题题库》',
                '《模拟试卷》2套',
                '《仿真预测卷》2套',
                '《单元测试题库》'
            ],
            zsfw: [
                '在线答疑',
                '移动看课',
                '学习追踪',
                '报名协助',
                '通用学习计划',
                '考试结束后答题解析',
                '两年学习服务',
            ],
            kcbz: [
                {
                    tit: '冲刺专题课',
                    desc: [
                        '以考点形式理清科目内各种真题类型，以及解答思路；',
                        '归纳总结记忆技巧，精简核心考试信息；',
                        '设置实用性更强实战专题，攻克考试盲区，针对性练习；',
                        '快速记忆，攻克特殊提醒阶梯思路，增强实战能力'
                    ]
                },
            ],
            jg: {
                gg: 900,
                // sw: 1998,
                qk: 2980
            },
            routerid: {
                course_category_id: 1165,
                class_type_id: 30
            },
        },

    }
}