<template>
  <div style="width: 100%; height: 100%">
    <div :id="id"></div>
  </div>
</template>

<script>
import api from "@/api/api";
export default {
  name: "Videos",
  data() {
    return {
      vodPlayerJs: "https://player.polyv.net/script/player.js",
    };
  },
  props: {
    vid: {
      type: String,
      default: "",
    },
    url: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "player",
    },
  },
  watch: {
    vid(val) {
      // console.log(val);
      this.changeVideo();
    },
    url(val) {
      // console.log(val);
      // this.changeVideo(val, 2);
    },
  },
  mounted() {
    this.loadPlayerScript(this.loadPlayer);
  },
  methods: {
    async getVidToken(vid) {
      // var getToken = async function() {
      // 调接口
      var data = {
        // token: localStorage.getItem("token"),
        vid: vid,
      };
      var token = "";
      await api
        .play_safe(data)
        .then((res) => {
          if (parseInt(res.data.code) == 1) {
            token = res.data.data.token;
          }
        })
        .catch((error) => {
          // return "";
        });
      return token;
    },
    // 保利威视频事件
    loadPlayerScript(callback) {
      if (!window.polyvPlayer) {
        const myScript = document.createElement("script");
        myScript.setAttribute("src", this.vodPlayerJs);
        myScript.onload = callback;
        document.body.appendChild(myScript);
      } else {
        callback();
      }
    },
    loadPlayer() {
      // setTimeout(() => {
      const polyvPlayer = window.polyvPlayer;
      let options = {
        autoplay: true,
        wrap: `#${this.id}`,
        logo: {
          logo_width: "auto", //Logo宽 默认 'auto'
          logo_height: "auto", //Logo高 默认 'auto'
          logo_url: require("../../assets/img/video_logo.png"), // Logo图片URL
          logo_pos: 2, //Logo显示位置，取值范围为：{0,1,2,3,4}，分别代表：隐藏、左上、右上、左下、右下
          logo_link: "", // 点击Logo的跳转链接 (选填)
          logo_alpha: 100, //Logo透明度，取值范围：(0,100)
          logoOffset: "95,5", //Logo偏移量，单位：百分比。坐标原点为播放器左上角，只在Logo位置为左上、右上时生效
          logo_resize: true, //默认值为false，为true时会根据播放器窗口大小动态调整Logo的尺寸，若设置了logo宽高则该参数不生效
        },
      };
      if (this.vid != "") {
        options.vid = this.vid;
        options.playsafe = this.getVidToken(this.vid);
      } else {
        options.url = this.url;
      }

      this.player = polyvPlayer(options);
      this.player.on("s2j_onPlayerInitOver", () => {
        this.$emit("videoover", {});
      });
    },
    // 点击切换视频
    changeVideo() {
      const option = {};
      if (this.vid != "" && this.vid) {
        option.vid = this.vid;
        option.playsafe = this.getVidToken(this.vid);
      } else {
        option.url = this.url;
      }
      this.player.changeVid(option);
    },
  },
  destroyed() {
    if (this.player) {
      this.player.destroy();
    }
  },
};
</script>

<style>
div {
  white-space: inherit;
}
</style>