<template>
  <div class="about">
    <img
      class="img"
      src="https://res-qd.qinxueedu.cn/resources/website/img/youshi2.png"
      alt
    />
    <div class="desc" style="top: 55px; left: 61px">
      <div class="tit je">
        优质的服务体系
        <img style="margin-left: 5px" src="../../assets/img/fuwu.png" alt />
      </div>
      <p class="je">
        打造咨询、教学、答疑、就业、职业发展规划的服务闭环，为学员提供专业的服务,班主任全程跟进测评、辅导、监督、提供方法指导、政策解读、就业咨询等服务
      </p>
    </div>
    <div class="desc" style="bottom: 48px; left: 61px">
      <div class="tit je">
        专业的教研团队
        <img
          style="margin-left: 5px"
          src="../../assets/img/jiaoyan (1).png"
          alt
        />
      </div>
      <p class="je">
        研究教材和考试大纲，把握命题规律，制定教学计划；提供反馈专业答疑服务；根据学院基础，为学员提供辅导方案。
      </p>
    </div>
    <div class="desc" style="top: 55px; right: 61px">
      <div class="tit">
        <img style="margin-right: 5px" src="../../assets/img/monitor.png" alt />
        随时随地的移动课堂
      </div>
      <p class>
        项目免费“直播课”和“精品课”播放，采用“直播+回放”的双重模式，让学员在线和离线学习；由教研老师提供答疑服务
      </p>
    </div>
    <div class="desc" style="bottom: 48px; right: 61px">
      <div class="tit">
        <img
          style="margin-right: 5px"
          src="../../assets/img/diversification_icon.png"
          alt
        />
        有记录的智能题库
      </div>
      <p class>
        题库每日一练、章节练习、历年真题、模拟考试等模块包含的测试题
        。凭借大数据记录答题轨迹，收集错题，检索知识点，依据做题记录自动生成测评报告
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Homeabout",
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
//网校名师结束

.about {
  height: 808px;
  background: #ffffff;
  border-radius: 12px;
  position: relative;
  .img {
    margin: 0 auto;
    padding-top: 165px;
    width: 822px;
    height: 480px;
    display: block;
  }
  .desc {
    width: 300px;
    position: absolute;
    .tit {
      display: flex;
      align-items: center;
      font-size: 24px;
      font-family: Microsoft YaqiHei;
      font-weight: bold;
      color: #333333;
    }
    p {
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #5c6788;
      line-height: 1.5;
      margin-top: 12px;
    }
    .je {
      justify-content: end;
      text-align: right;
    }
  }
}
</style>