<template>
  <div class="fixedl">
      <a href="http://tg-pc.liyoujiaoyu.net/yijian1.html" target="_blank"
        ><span>
          <p>一级建造师</p>
          <p>一建</p>
        </span></a
      >
      <a href="http://tg-pc.liyoujiaoyu.net/erjian1.html" target="_blank"
        ><span>
          <p>二级建造师</p>
          <p>二建</p>
        </span></a
      >
      <a href="http://tg-pc.liyoujiaoyu.net/anquan1.html" target="_blank"
        ><span>
          <p>中级安全工程师</p>
          <p>安全</p></span
        ></a
      >
      
      <a href="/course/2?isSelect=1291 " target="_blank"
        ><span>
          <p>监理工程师</p>
          <p>监理</p></span
        ></a
      >
  </div>
</template>

<script>
export default {
  name: "FixedLeft",
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.fixedl {
  // background: #fff;
  border-radius: 0px 4px 4px 0px;
  overflow: hidden;
  z-index: 999;
  position: fixed;
  line-height: 1;
  top: 690px;
  // transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  left: 0px;
  a {
    // transition: all 0.3s;
    width: 80px;
    overflow: hidden;
  }
  a span {
    transition: all 0.3s;
    cursor: pointer;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 80px;
    line-height: 80px;
    box-sizing: border-box;
    padding-left: 50px;
    font-weight: 400;
    font-family: PingFang SC;
    font-size: 16px;
    color: #0f2439;
    background: #fff url("../../assets/img/yijian_hover.png") no-repeat 27px
      19px;
    background-size: 26px;
  }
  a span p:first-child {
    opacity: 0;
  }
  a span p:last-child {
    width: 72px;
    text-align: center;
    position: absolute;
    bottom: 11px;
    line-height: 1;
    left: 0px;
  }
  a:nth-child(2) span {
    background: #fff url("../../assets/img/erjian.png") no-repeat 27px 19px;
  }
  a:nth-child(3) span {
    background: #fff url("../../assets/img/anquan.png") no-repeat 27px 19px;
  }
 
  a:nth-child(4) span {
    background: #fff url("../../assets/img/jianli.png") no-repeat 27px 19px;
  }
  a:hover {
    width: 180px;
  }
  a:hover span {
    flex-direction: inherit;
  }
  a:hover span::after {
    display: none;
  }
  a span::after {
    content: "";
    display: block;
    width: 60px;
    height: 1px;
    background: #eee;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  a:hover span p:first-child {
    opacity: 1;
  }
  a:hover span p:last-child {
    width: 0;
    overflow: hidden;
  }
  a:nth-child(1):hover span {
    color: #fff;
    border-radius: 0px 4px 4px 0px;
    background: #5c9dfc url("../../assets/img/yijian_hover (1).png") no-repeat
      16px 50%;
  }
  a:nth-child(2):hover span {
    border-radius: 0px 4px 4px 0px;
    color: #fff;
    background: #72c9f0 url("../../assets/img/erjian (1).png") no-repeat 16px
      50%;
  }
  a:nth-child(3):hover span {
    border-radius: 0px 4px 4px 0px;
    color: #fff;
    background: #ff9042 url("../../assets/img/anquan (1).png") no-repeat 16px
      50%;
  }
  a:nth-child(4):hover span {
    border-radius: 0px 4px 4px 0px;
    color: #fff;
    background: #00da95 url("../../assets/img/jianli (1).png") no-repeat 16px
      50%;
  }
}
</style>