<template>
  <div style="width: 100%;">
    <div class="homelive">
      <div class="l">
        <h2>{{ liveleft.live_title }}</h2>
        <div class="time">
          直播时间: {{ Number(liveleft.dispark_time.split(" ")[0].split("-")[1]) }}月{{
              Number(liveleft.dispark_time.split(" ")[0].split("-")[2])
          }}日
          {{
              liveleft.dispark_time.split(" ")[1].split(":")[0] +
              ":" +
              liveleft.dispark_time.split(" ")[1].split(":")[1]
          }}-{{ liveleft.finish_time.split(" ")[1] }}
        </div>
        <div class="avtar">
          <img :src="liveleft.teacher_image" :alt="liveleft.teacher_name" />
        </div>
        <p>主讲：{{ liveleft.teacher_name }}</p>
        <p style="font-weight: bold">{{ liveleft.cate_name }}</p>
        <div class="botom">
          <span class="jy" @click="handout(liveleft)" v-show="liveleft.n == 1 || liveleft.n == 2">
            <img src="../../assets/img/xiazai@2x.png" alt="" /> 查看讲义
          </span>
          <span class="zbz" v-show="liveleft.n == 1" @click="_live_detail(liveleft.live_id)"><img
              src="../../assets/img/zhibozhong.png" alt="" /> 进入直播</span>
          <span class="jjks" v-show="liveleft.n == 2" @click="_live_detail(liveleft.live_id)"><img
              src="../../assets/img/jijiang.png" alt="" /> 即将开始</span>
          <span class="yjs" v-show="liveleft.n == 3"><img src="../../assets/img/jieshu.png" alt="" /> 已结束</span>
        </div>
      </div>
      <div class="r">
        <div :class="item.n == 3 ? `li js ${item.live_id == liveleft.live_id ? 'active':''}` : `li wjs ${item.live_id == liveleft.live_id ? 'active':''}`" v-for="item in live" :key="item.live_id"
          @mouseenter="livehover(item)" @click="_live_detail(item.live_id)">
          <div class="lil">
            <div class="lilimg">
              <img :src="item.teacher_image" :alt="item.teacher_name" />
            </div>
            <span class="text1">{{ item.teacher_name }}</span>
          </div>
          <div class="lir">
            <div class="t">
              <div class="tl">
                {{ item.start_date
                }}<span>{{ item.dispark_time.split(" ")[1] }}</span>
              </div>
              <div :class="
                item.n == 1 ? 'tr zbz' : item.n == 2 ? 'tr jjks' : 'tr yjs'
              ">
                <img v-show="item.n == 1" src="../../assets/img/zhibozhong.png" alt="" />
                <img v-show="item.n == 2" src="../../assets/img/jijiang.png" alt="" />
                <img v-show="item.n == 3" src="../../assets/img/jieshu.png" alt="" />
                {{
                    item.n == 1 ? "直播中" : item.n == 2 ? "即将开始" : "已结束"
                }}
              </div>
            </div>
            <div class="b">
              <div class="bl">{{ item.user_limit == 1 ? "VIP" : "免费" }}</div>
              <div class="br text1">{{ item.live_title }}</div>
            </div>
          </div>
        </div>
        <!-- <div class="li wjs">
        <div class="lil">
          <img src="" alt="" />
          name
        </div>
        <div class="lir">
          <div class="t">
            <div class="tl">今天<span>20:00</span></div>
            <div class="tr zbz">
              <img src="../../assets/img/zhibozhong.png" alt="" />
              直播中
            </div>
          </div>
          <div class="b">
            <div class="bl">免费</div>
            <div class="br text1">二建-施工管理-2022年考前一小时公开课</div>
          </div>
        </div>
      </div>
      <div class="li wjs">
        <div class="lil">
          <img src="" alt="" />
          name
        </div>
        <div class="lir">
          <div class="t">
            <div class="tl">今天<span>20:00</span></div>
            <div class="tr jjks">
              <img src="../../assets/img/zhibozhong.png" alt="" />
              直播中
            </div>
          </div>
          <div class="b">
            <div class="bl">免费</div>
            <div class="br text1">二建-施工管理-2022年考前一小时公开课</div>
          </div>
        </div>
      </div>
      <div class="li js">
        <div class="lil">
          <img src="" alt="" />
          name
        </div>
        <div class="lir">
          <div class="t">
            <div class="tl">今天<span>20:00</span></div>
            <div class="tr yjs">
              <img src="../../assets/img/zhibozhong.png" alt="" />
              直播中
            </div>
          </div>
          <div class="b">
            <div class="bl">免费</div>
            <div class="br text1">二建-施工管理-2022年考前一小时公开课</div>
          </div>
        </div>
      </div> -->
      </div>
    </div>
    <el-dialog title="提示" :visible.sync="centerDialogVisible" :show-close="false" width="734px" custom-class="dailogbox"
      center>
      <div slot="title" class="dialog-tit">
        <span>直播讲义</span>
        <span>{{ liveleft.live_title }}</span>
        <img style="width: 20px; height: 20px; cursor: pointer" src="../../assets/img/classclose.png" alt=""
          @click="centerDialogVisible = false" />
      </div>
      <div class="handoutbody">
        <ul v-if="handoutlist.length > 0">
          <li v-for="item in handoutlist" :key="item.live_book_id">
            <div class="l">
              <img :src="imgsrc(item.title)" alt="" />
              <h2>{{ item.title }}</h2>
              <span>文件大小：{{ item.format_size }}</span>
            </div>
            <div class="r">
              <a :href="item.url" target="_blank" rel="noopener noreferrer">
                查看</a>
            </div>
          </li>
        </ul>
        <div v-else style="text-align: center">
          <img style="width: 380px; margin-top: 67px" src="../../assets/img/nojiangyi.png" alt="" />
        </div>
      </div>
      <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="centerDialogVisible = false"
          >确 定</el-button
        >
      </span> -->
    </el-dialog>
  </div>
</template>

<script>
import api from "../../api/api";
export default {
  props: {
    live: {
      default: [],
      type: Array,
    },
  },
  data() {
    return {
      centerDialogVisible: false,
      handoutlist: [],
      liveleft: {
        live_title: "",
        start_date: "2022-07-05",
        start_time: "2022-07-05",
        dispark_time: "2022-07-05 17:41:57",
        finish_time: "2022-07-09 15:00",
      },
    };
  },
  watch: {
    live(e) {
      console.log(e);
      this.liveleft = e[0];
    },
  },
  methods: {
    handout(e) {
      api
        .live_file_book({
          live_file_id: e.live_field_id,
          page: 1,
          page_size: 100,
        })
        .then((res) => {
          if (res.data.code == 1) {
            this.handoutlist = res.data.data.list;
            this.centerDialogVisible = true;
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
          // this.$message.error{}
        });
    },
    imgsrc(e) {
      let a = e.split(".");
      let b = a[a.length - 1];
      let c = [
        "txt",
        "zip",
        "xlsx",
        "xls",
        "tiff",
        "tar",
        "rar",
        "ppt",
        "png",
        "md",
        "jpg",
        "jpeg",
        "gz",
        "docx",
        "doc",
        "csv",
        "bz2",
        "bmp",
      ].indexOf(b);
      if (c > -1) {
        return require(`../../assets/img/${b}.png`);
      } else {
        return require(`../../assets/img/tongyong.png`);
      }
      // console.log(a[a.length - 1])
    },
    //直播切换
    livehover(e) {
      this.liveleft = e;
    },
    //直播详情
    _live_detail(val) {
      let data = {
        live_id: val,
      };
      api.live_detail(data).then((res) => {
        //  console.log(res)
        if (res.data.code == 1) {
          //  console.log(res.data.data.url)
          window.open(res.data.data.url);
        } else if (parseInt(res.data.code) == 401) {
          this.$message.error({
            message: "请先登录!",
            duration: "2000",
          });
          this.$router.push({
            name: "Login",
          });
        } else if (parseInt(res.data.code) == 0) {
          if (res.data.data.error_code == 4) {
            this.dialogVisible = true;
            this.show_type = 2;
            // this.getSubject();
          } else {
            this.$message.error(res.data.msg);
          }
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.handoutbody ul li:hover {
  background: #ebf2ff;

  .l {
    h2 {
      color: #3f7dff;
    }

    span {
      color: #3f7dff;
    }
  }
}

.handoutbody ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 8px 21px 8px 12px;
  border-bottom: 1px solid #f2f2f2;

  .l {
    display: flex;
    align-items: center;

    img {
      width: 35px;
    }

    h2 {
      width: 320px;
      font-size: 16px;
      font-weight: 500;
      color: #000b2f;
      margin-left: 10px;
      margin-right: 20px;
    }

    span {
      font-size: 14px;
      font-weight: 400;
      color: #999999;
    }
  }

  .r {
    width: 50px;
    height: 24px;
    background: #3f7dff;
    border-radius: 4px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    cursor: pointer;

    a {
      font-size: 14px;
      color: #ffffff;
    }
  }
}

::v-deep .dailogbox {
  border-radius: 8px;
}

::v-deep .el-dialog__body {
  padding: 0 18px;
  overflow-y: auto;
  height: 430px;
}

.dialog-tit {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border-bottom: 1px solid #f2f2f2;

  span:first-child {
    font-weight: 500;
    font-size: 14px;
  }

  span {
    font-size: 18px;
    font-weight: bold;
    color: #3f7dff;
  }
}

::v-deep .el-dialog__header {
  padding: 0;
}

.homelive {
  display: flex;
  width: 100%;
  justify-content: space-between;

  .r {
    width: 533px;
    overflow: hidden;

    .js {
      background: #fafafa;
    }

    .wjs {
      background: #f3f9ff;
    }

    .active {
      background: #3f7dff;

      .lil,
      .lir .b .br,
      .lir .t .tl span,
      .lir .t .tl {
        color: #fff !important;
      }

      .lir .tr {
        box-sizing: border-box;
        border: 1px solid #ffffff;
        background: transparent !important;
      }
    }

    .wjs:hover {
      background: #3f7dff;

      .lil,
      .lir .b .br,
      .lir .t .tl span,
      .lir .t .tl {
        color: #fff;
      }

      .lir .tr {
        box-sizing: border-box;
        border: 1px solid #ffffff;
        background: transparent !important;
      }
    }

    .li {
      margin-bottom: 12px;
      display: flex;
      cursor: pointer;
      align-items: center;
      padding: 15px 20px;
      transition: all 0.3s;
      border-radius: 6px;

      .lir {
        width: 100%;

        .b {
          display: flex;
          overflow: hidden;
          align-items: center;

          .bl {
            width: 47px;
            height: 25px;
            background: linear-gradient(90deg, #e7733b, #ea9758);
            border-radius: 4px;
            font-size: 16px;
            font-family: Adobe Heiti Std;
            font-weight: normal;
            color: #ffffff;
            text-align: center;
            line-height: 25px;
            margin-right: 9px;
          }

          .br {
            width: 360px;
            font-size: 18px;
            font-weight: 400;
            color: #1a1a1a;
          }
        }

        .t {
          display: flex;
          justify-content: space-between;
          margin-bottom: 15px;

          .tl {
            font-size: 18px;
            font-weight: 400;
            color: #1a1a1a;

            span {
              font-size: 14px;
              margin-left: 5px;
              font-weight: 400;
              color: #808080;
            }
          }

          .tr {
            width: 100px;
            height: 30px;
            border-radius: 15px;
            font-size: 14px;
            font-weight: 400;
            color: #ffffff;
            display: flex;
            line-height: 30px;
            align-items: center;
            justify-content: center;

            img {
              width: 14px;
              margin-right: 7px;
            }
          }

          .zbz {
            background: linear-gradient(90deg, #e7733b, #ea9758);
          }

          .jjks {
            background: linear-gradient(90deg, #7cabef, #3f7dff);
          }

          .yjs {
            background: #e0e0e0;
          }
        }
      }

      .lil {
        line-height: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 16px;
        min-width: 60px;
        margin-right: 15px;
        font-weight: 400;
        color: #666666;

        .lilimg {
          width: 60px;
          height: 60px;
          border-radius: 50%;
          overflow: hidden;
          margin-bottom: 9px;
          position: relative;
        }

        img {
          height: 100%;
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }

  .l {
    width: 783px;
    overflow: hidden;
    height: 495px;
    background: url("../../assets/img/beijing@2x.png") no-repeat;
    background-size: 100% 100%;

    h2 {
      font-size: 38px;
      font-weight: 500;
      color: #1a1a1a;
      margin-top: 97px;
      text-align: center;
    }

    .time {
      text-align: center;
      font-size: 20px;
      font-weight: 400;
      color: #3f7dff;
      padding: 21px 0 38px;
    }

    .avtar {
      text-align: center;
      margin-bottom: 17px;
      width: 76px;
      height: 76px;
      border-radius: 50%;
      overflow: hidden;
      position: relative;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      text-align: center;

      img {
        height: 100%;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    p {
      text-align: center;
      font-size: 18px;
      font-weight: 400;
      color: #666666;
    }

    .botom {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 30px;
    }

    .jy {
      background: #ffffff;
      border: 1px solid #906a51;
      color: #906a51;
      margin-right: 50px;
    }

    .zbz {
      background: linear-gradient(90deg, #e7733b, #ea9758);
    }

    .jjks {
      background: linear-gradient(90deg, #7cabef, #3f7dff);
    }

    .yjs {
      background: #e0e0e0;
    }

    span {
      width: 238px;
      color: #ffffff;
      height: 51px;
      font-size: 20px;
      font-weight: 400;
      border-radius: 25px;
      justify-content: center;
      display: flex;
      cursor: pointer;
      align-items: center;
      line-height: 51px;
      text-align: center;

      img {
        width: 17px;
        margin-right: 8px;
      }
    }
  }
}
</style>