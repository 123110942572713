<template>
  <div class="fixedr">
    <!-- <div class="t">
        <img src="../../assets/img/400-656-1156.png" alt />
        <p>全国免费咨询电话</p>
      </div> -->
    <div class="list">
      <div class="li" @click="downloadapp">
        <div class="img">
          <img
            src="https://res-qd.qinxueedu.cn/uploads/prod/20230412/86956d8b637e37f3c89303956844ef2b.png"
            alt="APP下载"
          />
        </div>
        <p>APP下载</p>
      </div>

      <div class="li" @click="downloadapp">
        <div class="img">
          <img
            src="https://res-qd.qinxueedu.cn/uploads/prod/20230724/6fae1f688c55ddfee42379779a8da010.jpg"
            alt="微信小程序"
          />
        </div>
        <p>微信小程序</p>
      </div>

      <div class="li" @click="servewx">
        <div class="img">
          <img src="../../assets/img/qrcode-qxkj.jpg" alt />
        </div>
        <p>公众号</p>
      </div>

      <div class="li">
        <div class="img">
          <img :src="seat_jianzhu" alt />
          添加老师微信
          <br />了解更多考试信息
        </div>
        <p>在线咨询</p>
      </div>

      <div class="li">
        <a href="#totop">
          <p>返回顶部</p>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FixedRight",
  data() {
    return {};
  },
  props: {
    seat_jianzhu: "",
  },
  methods: {
    downloadapp() {
      window.open("/downloadapp");
      // this.$router.push({ path: "/downloadapp" });
    },
    servewx() {
      window.open("/servewx");
      // this.$router.push({ path: "/servewx" });
    },
  },
};
</script>

<style lang="scss" scoped>
.fixedr {
  z-index: 999;
  position: fixed;
  top: 690px;
  line-height: 1;
  // transform: translateY(-50%);
  right: 0px;
  .t {
    display: flex;
    flex-direction: column;
    text-align: center;
    img {
      width: 152px;
      margin-bottom: 5px;
    }
    p {
      font-size: 12px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #999999;
    }
  }
  .list {
    display: flex;
    flex-direction: column;
    align-items: center;
    .li {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      text-align: center;
      width: 80px;
      height: 80px;
      background-size: 20px;
      color: #333333;
      background: #fff url("../../assets/img/xiazai.png") no-repeat 50% 20px;
      p {
        font-size: 14px;
        margin-top: 45px;
        font-family: PingFang SC;
        font-weight: 400;
      }
    }
    .li:nth-child(2) {
      background: #fff url("../../assets/img/xiaochengxu_icon.png") no-repeat 50% 20px;
    }
    .li:nth-child(1) {
      border-radius: 4px 0px 0px 0px;
      .img {
        display: none;
        position: absolute;
        background: #fff;
        top: -60px;
        left: -190px;
        padding: 18px;
        border-radius: 4px;
        flex-direction: column;
        align-items: center;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 20px;
        justify-content: center;
        img {
          width: 144px;
          height: 144px;
          margin-bottom: 5px;
        }
      }
      position: relative;
      background: #fff url("../../assets/img/xiazai.png") no-repeat 50% 20px;
    }
    .li:nth-child(2) {
      .img {
        display: none;
        position: absolute;
        background: #fff;
        top: -60px;
        left: -190px;
        padding: 18px;
        border-radius: 4px;
        flex-direction: column;
        align-items: center;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 20px;
        justify-content: center;
        img {
          width: 144px;
          height: 144px;
          margin-bottom: 5px;
        }
      }
      position: relative;
      background: #fff url("../../assets/img/xiaochengxu_icon.png") no-repeat 50% 20px;
    }

    .li:nth-child(3) {
      .img {
        display: none;
        position: absolute;
        background: #fff;
        top: -60px;
        left: -190px;
        padding: 18px;
        border-radius: 4px;
        flex-direction: column;
        align-items: center;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 20px;
        justify-content: center;
        img {
          width: 144px;
          height: 144px;
          margin-bottom: 5px;
        }
      }
      position: relative;
      background: #fff url("../../assets/img/erweima.png") no-repeat 50% 20px;
    }

    .li:nth-child(4) {
      .img {
        display: none;
        position: absolute;
        background: #fff;
        top: -60px;
        left: -190px;
        padding: 18px;
        border-radius: 4px;
        flex-direction: column;
        align-items: center;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 20px;
        justify-content: center;
        img {
          width: 144px;
          height: 144px;
          margin-bottom: 5px;
        }
      }
      position: relative;
      background: #fff url("../../assets/img/zixun (3).png") no-repeat 50% 20px;
    }
    .li:nth-child(5) {
      background: #fff url("../../assets/img/ding.png") no-repeat 50% 20px;
      border-radius: 0px 0px 0px 4px;
    }
    .li:nth-child(1):hover {
      .img {
        display: flex;
      }
      color: #fff;
      background: #3e7eff url("../../assets/img/xiazai_hover.png") no-repeat 50%
        20px;
    }
    .li:nth-child(2):hover {
      .img {
        display: flex;
      }
      color: #fff;
      background: #3e7eff url("../../assets/img/xiaochengxu_icon_hover.png") no-repeat
        50% 20px;
    }

    .li:nth-child(3):hover {
      .img {
        display: flex;
      }
      color: #fff;
      background: #3e7eff url("../../assets/img/erweima_hover.png") no-repeat
        50% 20px;
    }

    .li:nth-child(4):hover {
      .img {
        display: flex;
      }
      color: #fff;
      background: #3e7eff url("../../assets/img/zixun_hover.png") no-repeat 50%
        20px;
    }
    .li:nth-child(5):hover {
      a {
        color: #fff;
      }
      color: #fff;
      background: #3e7eff url("../../assets/img/ding_hover.png") no-repeat 50%
        20px;
    }
  }
}
</style>