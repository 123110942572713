<template>
  <div class="teacher">
    <div class="l">
      <ul>
        <li
          :class="teacherdata.id == teacherlist[0].id ? 'act' : ''"
          @mouseenter="teacherhover(1)"
          style="background: #eaeef5"
        >
          <img :src="teacherlist[0].img" alt="" />
        </li>
        <li
          :class="teacherdata.id == teacherlist[1].id ? 'act' : ''"
          @mouseenter="teacherhover(2)"
          style="background: #f4f3f6"
        >
          <img :src="teacherlist[1].img" alt="" />
        </li>
        <li
          :class="teacherdata.id == teacherlist[2].id ? 'act' : ''"
          @mouseenter="teacherhover(3)"
          style="background: #ecf1f2"
        >
          <img :src="teacherlist[2].img" alt="" />
        </li>
        <li
          :class="teacherdata.id == teacherlist[3].id ? 'act' : ''"
          @mouseenter="teacherhover(4)"
          style="background: #ecf1f2"
        >
          <img :src="teacherlist[3].img" alt="" />
        </li>
        <li
          :class="teacherdata.id == teacherlist[4].id ? 'act' : ''"
          @mouseenter="teacherhover(5)"
          style="background: #eaeef5"
        >
          <img :src="teacherlist[4].img" alt="" />
        </li>
        <li
          :class="teacherdata.id == teacherlist[5].id ? 'act' : ''"
          @mouseenter="teacherhover(6)"
          style="background: #f4f3f6"
        >
          <img :src="teacherlist[5].img" alt="" />
        </li>
        <li
          :class="teacherdata.id == teacherlist[6].id ? 'act' : ''"
          @mouseenter="teacherhover(7)"
          style="background: #f4f3f6; margin-bottom: 0"
        >
          <img :src="teacherlist[6].img" alt="" />
        </li>
        <li
          :class="teacherdata.id == teacherlist[7].id ? 'act' : ''"
          @mouseenter="teacherhover(8)"
          style="background: #ecf1f2; margin-bottom: 0"
        >
          <img :src="teacherlist[7].img" alt="" />
        </li>
        <li
          :class="teacherdata.id == teacherlist[8].id ? 'act' : ''"
          @mouseenter="teacherhover(9)"
          style="background: #eaeef5; margin-bottom: 0"
        >
          <img :src="teacherlist[8].img" alt="" />
        </li>
      </ul>
    </div>
    <div class="r">
      <div class="t">
        {{ teacherdata.name }}
        <span>{{ teacherdata.curse }}</span>
      </div>
      <div class="z">
        <span v-for="item in teacherdata.tag" :key="'teacher' + item">{{
          item
        }}</span>
      </div>
      <div class="desc">
        {{ teacherdata.desc }}
      </div>
      <img :src="teacherdata.img" alt />
    </div>
  </div>
</template>

<script>
export default {
  name: "Hometeacher",
  data() {
    return {
      teacherdata: {
        id: 1,
        name: "金亮",
        tag: ["图表口诀", "风趣幽默"],
        curse: "一级建造师 / 二级建造师",
        desc: "注册建造师、注册结构工程师、注册监理工程师，中国建筑集团技术顾问，北京某高校特聘讲师，具有丰富的理论与实践经验。从事建造师、监理工程师培训近十年，参与过考试教材、相关辅导书籍的编写。",
        img: "https://res-qd.qinxueedu.cn/resources/website/img/teacher/1.png",
      },
      teacherlist: [
        {
          id: 8,
          name: "房超",
          tag: ["特色教学", "房大神"],
          curse: "二级建造师",
          desc: "国内211高校毕业，国家一级建造师。多年从事教育、培训行业，主攻法规、市政实务，讲课感染力极强，利用“二法”--讲法、考法讲解方式，让法规讲解浅显易懂，对学员高效备考得分起到重要作用。被学员誉为“房大神”。",
          img: "https://res-qd.qinxueedu.cn/resources/website/img/teacher/8.png",
        },
        {
          id: 5,
          name: "王培山",
          tag: ["深入浅出", "独特记忆方法"],
          curse: "中级注册安全工程师",
          desc: "注册安全工程师的全国知名授课老师，注册安全工程师考试研究专家，注册一级消防工程师，注册一级建造师。专业从事国家注册安全工程师的授课工作，深入浅出的讲授课程，不断钻研教学方法和考试技巧，研究了安全考试中相关数字的独特记忆方法。培养了学员针对注册安全工程师考试的良好学习方法和考试思维",
          img: "https://res-qd.qinxueedu.cn/resources/website/img/teacher/5.png",
        },
        {
          id: 3,
          name: "黄海刚",
          tag: ["善于总结", "独门绝技"],
          curse: "监理工程师",
          desc: "工学硕士、BIM工程师、全国监理工程师（土建）。授课以大纲为本，教材为根，善于结合现场实际工作经验将知识点讲透，针对现场实际生产建设工程活动有丰富管理经验，授课善于总结归纳，能让学员学习变得轻松，达到事半功倍的效果，深受学员好评。",
          img: "https://res-qd.qinxueedu.cn/resources/website/img/teacher/10.png",
        },
        {
          id: 4,
          name: "韩放",
          tag: ["思路清晰", "央企大牛"],
          curse: "中级注册安全工程师",
          desc: "曾担任央企技术负责人，从事市政实务和安全建筑施工教育多年，参与编写多部建造师工程用书考试教辅，出版书籍，对安全工程师考试考点分析透彻，授课思路清晰，重难点突出，结合考试大纲，针对性强，擅长提炼知识点进行归纳总结对比分析，简单幽默，深受学员认可。",
          img: "https://res-qd.qinxueedu.cn/resources/website/img/teacher/11.png",
        },
        {
          id: 1,
          name: "金亮",
          tag: ["图表口诀", "风趣幽默"],
          curse: "一级建造师 / 二级建造师",
          desc: "注册建造师、注册结构工程师、注册监理工程师，中国建筑集团技术顾问，北京某高校特聘讲师，具有丰富的理论与实践经验。从事建造师、监理工程师培训近十年，参与过考试教材、相关辅导书籍的编写。",
          img: "https://res-qd.qinxueedu.cn/resources/website/img/teacher/1.png",
        },
        {
          id: 6,
          name: "葛卫林",
          tag: ["紧扣考点", "出版教辅"],
          curse: "中级注册安全工程师",
          desc: "建造师考试《建设工程法规》、注册安全工程师《安全生产法规》讲师。注册一级建造师、注册中级安全工程师，兼职律师。个人出版或参与主编关于建造师考试和中级安全工程师考试教辅多本。长期从事建设工程类和安全类辅导培训，有丰富的考试培训经验，讲授课程细腻，紧扣考点，重点突出，针对性强",
          img: "https://res-qd.qinxueedu.cn/resources/website/img/teacher/6.png",
        },
        {
          id: 7,
          name: "梁静",
          tag: ["总结归纳", "解题技巧"],
          curse: "二级建造师",
          desc: "国家二级建筑，公路，市政，一级建筑，市政，公路建造师，造价工程师，主讲公路，造价计量。长期从事工程建筑、市政，公路设计，施工，造价相关工作。授课特点：善于总结归纳，复杂问题简单化及独特的解题思路和技巧。",
          img: "https://res-qd.qinxueedu.cn/resources/website/img/teacher/7.png",
        },
        {
          id: 2,
          name: "韩铎",
          tag: ["幽默风趣", "经验丰富"],
          curse: "一级建造师",
          desc: "一级建造师、从事建造培训以来，主编（副主编）多部机电类教辅；授课中不仅把工程经验和图表融入教学，而且善于把抽象的问题形象化，把教材逻辑关系清晰化，让学员在理解的基础上加深记忆，建立体系，从容应对考试，深受各地学员的喜爱！",
          img: "https://res-qd.qinxueedu.cn/resources/website/img/teacher/13.png",
        },
        {
          id: 9,
          name: "陈浩",
          tag: ["记忆口诀", "重点突出"],
          curse: "中级注册安全工程师",
          desc: "交通部检测工程师、高级工程师、注册一级建造师、注册安全工程师；教学善于将复杂枯燥的考点讲得简单有趣，且重点突出，总结的记忆口诀广受好评。",
          img: "https://res-qd.qinxueedu.cn/resources/website/img/teacher/9.png",
        },
      ],
    };
  },
  methods: {
    //教师hover
    teacherhover(e) {
      this.teacherdata = this.teacherlist[e - 1];
    },
  },
};
</script>

<style lang="scss" scoped>

.teacher {
  position: relative;
  display: flex;
  padding: 40px 30px;
  background: #fff;
  border-radius: 12px;
  .l {
    ul {
      width: 510px;
      display: flex;
      flex-wrap: wrap;
      li {
        border: 2px solid #fff;
        width: 160px;
        height: 100px;
        overflow: hidden;
        text-align: center;
        cursor: pointer;
        box-sizing: border-box;
        border-radius: 6px;
        margin: 0 10px 10px 0;
        img {
          width: 50%;
          margin-top: 10px;
          transform: rotateY(180deg);
        }
      }
      .act {
        background: #fff !important;
        border: 2px solid #3e7eff !important;
      }
    }
  }
  .r {
    margin-left: 70px;
    .t {
      font-size: 42px;
      font-weight: 600;
      font-family: PingFang SC;
      color: #1d1d1d;
      span {
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #3e7eff;
      }
    }
    .z {
      margin: 30px 0 40px;
      display: flex;
      align-items: center;
      span {
        padding: 5px 20px;
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #505050;
        background: #f1f1f1;
        border-radius: 12px 10px 10px 0px;
        margin-right: 20px;
      }
    }
    img {
      position: absolute;
      bottom: 0;
      right: 10px;
    }
    .desc {
      width: 421px;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #777777;
    }
  }
}
</style>