<template>
  <div class="logincard">
    <div v-if="!token || token == ''">
      <div class="t">
        <img src="../../assets/img/denglu_liyou.png" alt />
        <p>注册有好礼，好课学不停</p>
      </div>
      <div class="input">
        <input
          type="text"
          placeholder="账号"
          v-model="form.account"
          maxlength="11"
          @keyup.enter="goLogin"
        />
        <input
          type="password"
          placeholder="密码"
          v-model="form.password"
          @keyup.enter="goLogin"
        />
      </div>
      <div class="descc">
        <div>
          <el-checkbox v-model="checked"></el-checkbox>
          <span>自动登录</span>
        </div>
        <span>
          <router-link to="forgetPwd">忘记密码？</router-link>
        </span>
      </div>
      <div class="buton">
        <el-button style="width: 168px" type="primary" round @click="goLogin"
          >学员登录</el-button
        >
        <router-link to="register">
          <el-button style="width: 168px" class="nobk" type="primary" round
            >注册</el-button
          >
        </router-link>
      </div>
      <div
        style="
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 16px;
          font-weight: 400;
          color: #999999;
          font-family: PingFang SC;
          text-align: center;
        "
      >
        <img
          style="width: 12px; height: 15px; margin-right: 7px"
          src="../../assets/img/phone (1).png"
          alt
        />
        全国免费咨询电话：<span style="color: #409eff">400-656-1156</span>
      </div>
    </div>
    <div class="islogin" v-else>
      <div class="islogint">
        <div class="istl">
          <img :src="headerimg" alt="" />
        </div>
        <div class="istr">
          <p>您好，学员{{ nickname }}</p>
          <span> 欢迎登录勤学教育，现在开始学习吧～ </span>
        </div>
      </div>
      <div class="isloginz">
        <img
          @click="$router.push({ path: '/personal/8' })"
          src="../../assets/img/xxzx.png"
          alt=""
        />
        <img
          @click="$router.push({ path: '/chapter/9/1' })"
          src="../../assets/img/tklx.png"
          alt=""
        />
        <a href="/help/help.html"
          ><img src="../../assets/img/bzzx.png" alt=""
        /></a>
        <img
          @click="$router.push({ path: '/downloadapp' })"
          src="../../assets/img/appxz.png"
          alt=""
        />
      </div>
      <img
        src="../../assets/img/400 (1)tel.png"
        style="width:520px;height80px;"
        alt=""
      />
    </div>
  </div>
</template>

<script>
import api from "../../api/api";
export default {
  name: "Logincard",
  data() {
    return {
      headerimg: "",
      token: "",
      nickname: "",
      checked: true,
      form: {
        account: "", //账号
        password: "", //密码
        is_page_id: "", //
      },
    };
  },
  created() {
    this.token = localStorage.getItem("token")
      ? localStorage.getItem("token")
      : "";

    if (this.token && this.token != "") {
      api.center().then((res) => {
        console.log(res);
        this.nickname = res.data.data.nickname;
        this.headerimg = require(`../../assets/img/${res.data.data.avatar == '' ? 'touxiang':res.data.data.avatar}.png`);
      });
    }
  },
  methods: {
    // 登录
    goLogin() {
      const reg = /^1[3-9][0-9]{9}$/;
      if (
        this.form.account == "" ||
        this.form.account <= 10 ||
        !reg.test(this.form.account)
      ) {
        this.$message.error({
          duration: 1500,
          message: "请输入正确账号!",
        });
        return false;
      } else if (this.form.password == "") {
        this.$message.error({
          duration: 1500,
          message: "请输入正确密码!",
        });
        return;
      } else {
        api.login(this.form).then((res) => {
          if (parseInt(res.data.code) == 1) {
            localStorage.setItem("token", res.data.data.token);
            this.$message.success({
              duration: 1500,
              message: res.data.msg,
            });
            this.$router.history.go(0);
          } else {
            this.$message.error({
              duration: 1500,
              message: res.data.msg,
            });
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.islogin {
  background: #fff;
  padding: 30px 30px 15px 30px;
  border-radius: 12px;

  img {
    cursor: pointer;
  }

  .islogint {
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    .istl {
      width: 70px;
      height: 70px;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 18px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .istr {
      font-size: 18px;
      font-weight: 500;
      color: #3e7eff;
      line-height: 24px;

      span {
        color: #666666;
      }
    }
  }

  .isloginz {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    img {
      width: 250px;
      height: 90px;
      margin-bottom: 20px;
    }
  }
}

.logincard {
  background: url("https://res-qd.qinxueedu.cn/resources/website/img/login_bg.png")
    no-repeat center;
  background-size: 100%;
  width: 580px;
  height: 454px;

  .t {
    text-align: center;

    img {
      width: 180px;
      margin-top: 44px;
      margin-bottom: 4px;
    }

    p {
      font-size: 15px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #646f8e;
    }
  }

  .input {
    margin-top: 37px;

    input {
      display: block;
      margin: 0 auto 16px;
      width: 360px;
      height: 52px;
      box-sizing: border-box;
      padding: 18px 21px;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 400;
      border: 1px solid #c5cbd9;
      color: #646f8e;
      border-radius: 4px;
    }
  }

  .descc {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    width: 360px;
    margin: 3px auto 0;
    justify-content: space-between;

    span a {
      font-size: 14px;
      margin-left: 10px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #666666;
    }
  }

  .buton {
    display: flex;
    align-items: center;
    width: 360px;
    margin: 30px auto;
    justify-content: space-between;
  }
}
</style>